import { render, staticRenderFns } from "./outer-driver-details.vue?vue&type=template&id=42d42dfb&scoped=true"
import script from "./outer-driver-details.vue?vue&type=script&lang=js"
export * from "./outer-driver-details.vue?vue&type=script&lang=js"
import style0 from "./outer-driver-details.vue?vue&type=style&index=0&id=42d42dfb&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42d42dfb",
  null
  
)

export default component.exports
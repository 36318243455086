<template>
  <!-- 外请车对账单列表明细 -->
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main mb24px">
        <ayl-new-table
          v-loading="loading"
          :data="tableData"
          pagination
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :pager-count="5"
          :current-page.sync="current"
          :total="totalNum"
          :page-size="10"
          @size-change="handleSizeChange"
          @ayl-current-change="handleCurrentChange"
          @getSearchPramas="getSearchPramas"
          @selection-change="handleSelectionChange"
        >
          <template #after>
            <div>
              <el-button type="primary" @click="onExport">全部导出</el-button>
              <el-button
                v-if="$route.query.accountStatus != 1"
                type="success"
                @click="openDialog"
                >移入订单</el-button
              >
              <el-button
                v-if="$route.query.accountStatus != 1"
                type="success"
                class="btn-success"
                @click="onDelate"
                >移出订单</el-button
              >
            </div>
            <el-button @click="$router.go(-1)">返回</el-button>
          </template>
        </ayl-new-table>

        <!-- 移入订单弹窗列表 -->
        <el-dialog
          title="添加订单"
          :visible.sync="dialogVisible"
          width="70%"
          :before-close="handleClose"
        >
          <outer-driver-add
            @getOrderIdList="getOrderIdList"
            :queryId="$route.query.queryId"
            :key="counst"
          ></outer-driver-add>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="onAdd">确 定</el-button>
            <el-button @click="dialogVisible = false">取 消</el-button>
          </span>
        </el-dialog>

        <!--导出-->
        <el-dialog
          title="选择导出的数据"
          :visible.sync="dialogExport"
          width="900px"
          center
        >
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
            <el-checkbox
              :label="item.index"
              v-for="(item, idx) in checkList"
              :key="idx"
              >{{ item.value }}</el-checkbox
            >
          </el-checkbox-group>
          <span slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              @click="exportExcel"
              class="mr24px"
              :loading="btnLoading"
              >确 定</el-button
            >
            <el-button @click="dialogExport = false">取 消</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "@utils/mixins";
import newTableMixins from "../../utils/new-table-mixins";
import OuterDriverAdd from "./outer-driver-add";
export default {
  name: "",
  mixins: [newTableMixins, mixins.$exportCheckAll],
  components: { OuterDriverAdd },
  data() {
    return {
      nav: [
        { name: "外请车对账" },
        { name: "对账单列表" },
        { name: "对账单明细", path: "/accounting/outer-driver-details" },
      ],

      btnLoading: false,
      checkIndex: [],
      checkList: [],
      dialogExport: false,

      counst: 0,
      dialogVisible: false,
      orderIdList: [],
      orderIdLists: [],

      tableData: {
        api: this.$api.accounting_getOrderListByAccountCheckingId,
        query: {
          accountCheckingId: this.$route.query.accountCheckingId,
          startTime: null,
          endTime: null,
          // queryContent: null,
        },
        searchData: [
          //   {
          //     title: "日期",
          //     type: "dateRange",
          //     model: ["startTime", "endTime"],
          //   },
          //   {
          //     title: "业务类型",
          //     type: "select",
          //     model: "orderType",
          //     placeholder: "请选择",
          //     option: this.$enums.OrderNewType.list,
          //   },
          //   {
          //     type: "input",
          //     model: "queryContent",
          //     placeholder: "内部驾驶员姓名",
          //   },
        ],

        columns: [
          {
            type: "selection",
          },
          {
            title: "客户名称",
            key: "partnerName",
            showTooltip: true,
          },
          {
            title: "结算客户",
            key: "partnerAccountName",
            showTooltip: true,
          },
          {
            title: "订单编号",
            width: "150px",
            showTooltip: true,
            key: "orderId",
          },
          {
            title: "运输日期",
            filter: "str2ymd",
            key: "transportTime",
            showTooltip: true,
          },
          {
            title: "提货车号",
            key: "plateNumber",
            showTooltip: true,
          },
          {
            title: "业务类型",
            key: "orderType",
            showTooltip: true,
          },
          {
            title: "重量",
            key: "categoryWeight",
            showTooltip: true,
          },
          {
            title: "成本单价",
            key: "costUnitPrice",
            showTooltip: true,
          },
          {
            title: "成本运费",
            key: "costCarrierFee",
            showTooltip: true,
          },
          {
            title: "拼车费",
            key: "carpoolingFee",
            showTooltip: true,
          },
          {
            title: "吊装费",
            key: "liftingFee",
            showTooltip: true,
          },
          {
            title: "过磅费",
            key: "weightFee",
            showTooltip: true,
          },
          {
            title: "卸车费",
            key: "unloadFee",
            showTooltip: true,
          },
          // {
          //   title: "应收金额",
          //   key: "orderCount",
          //   showTooltip: true,
          // },
          {
            title: "操作",
            width: "100px",
            align: "center",
            render: (h, ctx) => {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view": true,
                    },
                    on: {
                      click: () => {
                        this.goDetail(ctx.row);
                      },
                    },
                  },
                  "订单详情"
                ),
              ]);
            },
          },
        ],
        data: [],
      },
    };
  },
  methods: {
    getOrderIdList(val) {
      this.orderIdList = val;
    },

    onExport(onExport) {
      let params = {
        accountCheckingId: this.$route.query.accountCheckingId,
      }
      this.$api.accounting_queryOrderListByAccountCheckingId(params).then(res=>{
        if (res.length === 0) return this.$message.warning("暂无订单导出");
        this.orderIdLists = res;
        this.dialogExport = true;
        this.$api
          .GetExcelExportKeyAndColumn({
            excelKey: "orderListExport",
          })
          .then((res) => {
            this.checkList = res.chsList;
            this.checkIndex = this.checkList.map((e) => {
              return e.index;
            });
          });
      })

    },
    // 导出
    async exportExcel() {
      this.btnLoading = true;
      try {
        // const url = await this.$api.Excel({
        //   excelKey: "SteelPartnerAccount",
        //   indexList: this.checkIndex,
        //   params: {
        //     type: 0,
        //     orderStatus: null,
        //     startTime: this.$route.query.startTime || null,
        //     endTime: this.$route.query.endTime || null,
        //     orderType: this.orderType,
        //     queryId: this.$route.query.queryId,
        //     queryContent: this.queryContent,
        //     orderIds: this.orderIdLists,
        //   },
        // });
        const url = await this.$api.Excel({
          excelKey: "orderListExport",
          indexList: this.checkIndex,
          params: {
            orderIds: this.orderIdLists
          },
        });
        window.open(url);
        this.dialogExport = false;
        this.isSelection = [];
        this.orderIdLists = [];
        this.getListData();
      } catch (e) {
        // console.log(e)
      }
      this.btnLoading = false;
    },

    handleSelectionChange(val) {
      this.orderIdLists = val.map((item) => {
        return item.orderId;
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // 移出
    onDelate() {
      if (this.orderIdLists.length === 0)
        return this.$message.warning("请选择要移出的订单");
      let params = {
        accountCheckingId: this.$route.query.accountCheckingId,
        type: "0",
        orderIdList: this.orderIdLists,
      };
      this.$api.accounting_WQAccountAddOrRemoveOrder(params).then((res) => {
        this.$notify({
          title: "成功",
          message: "移出成功",
          type: "success",
        });
        this.getListData();
      });
    },
    // 移入
    onAdd() {
      if (this.orderIdList.length == 0) {
        return this.$message({
          message: "警告，请选择要移入的数据",
          type: "warning",
        });
      }
      this.dialogVisible = false;
      let params = {
        accountCheckingId: this.$route.query.accountCheckingId,
        type: "1",
        orderIdList: this.orderIdList,
      };
      this.$api.accounting_WQAccountAddOrRemoveOrder(params).then((res) => {
        this.$notify({
          title: "成功",
          message: "移入成功",
          type: "success",
        });
        this.getListData();
      });
    },
    openDialog() {
      this.dialogVisible = true;
      this.counst++;
    },

    goDetail(e) {
      console.log(e);
      this.$router.push({
        path: "/accounting/customer-accounting",
        query: {
          orderId: e.orderId,
        },
      });
    },
  },
};
</script>

<style lang="css" scoped>
::v-deep .search-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
}

.dialog-footer {
  display: flex;
  justify-content: center;
}
</style>

<template>
  <!-- 添加订单列表 -->
  <div>
    <ayl-new-table
      v-loading="loading"
      :data="tableData"
      pagination
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 50, 100]"
      :pager-count="5"
      :current-page.sync="current"
      :total="totalNum"
      :page-size="10"
      @size-change="handleSizeChange"
      @ayl-current-change="handleCurrentChange"
      @getSearchPramas="getSearchPramas"
      @selection-change="handleSelectionChange"
    >
      <template #after> </template>
    </ayl-new-table>
  </div>
</template>

<script>
import newTableMixins from "../../utils/new-table-mixins";
export default {
  name: "OuterDriverAdd",
  mixins: [newTableMixins],
  components: {},
  props: {
    queryId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      orderIdList: [],

      tableData: {
        api: this.$api.commonService_WQAccountCheckingList,
        query: {
          queryId: this.queryId,
          startTime: null,
          endTime: null,
          queryContent: null,
          type: 0,
        },
        searchData: [
            {
              title: "运输日期",
              type: "dateRange",
              model: ["startTime", "endTime"],
            },
          //   {
          //     title: "业务类型",
          //     type: "select",
          //     model: "orderType",
          //     placeholder: "请选择",
          //     option: this.$enums.OrderNewType.list,
          //   },
          //   {
          //     type: "input",
          //     model: "queryContent",
          //     placeholder: "内部驾驶员姓名",
          //   },
        ],

        columns: [
          {
            type: "selection",
          },
          {
            title: "客户名称",
            key: "partnerName",
            showTooltip: true,
          },
          {
            title: "结算客户",
            key: "partnerAccountName",
            showTooltip: true,
          },
          {
            title: "订单编号",
            width: "150px",
            showTooltip: true,
            key: "orderId",
          },
          {
            title: "运输日期",
            key: "transportTime",
            filter: 'str2ymd',
            width: "100px",
            align: "center",
            showTooltip: true,
          },
          {
            title: "提货车号",
            key: "plateNumber",
            showTooltip: true,
          },
          {
            title: "业务类型",
            key: "orderType",
            showTooltip: true,
          },
          {
            title: "重量",
            key: "categoryWeight",
            showTooltip: true,
          },
          {
            title: "运费单价",
            key: "earningUnitPrice",
            showTooltip: true,
          },
          {
            title: "运费",
            key: "earningFee",
            showTooltip: true,
          },
          {
            title: "拼车费",
            key: "carpoolingFee",
            showTooltip: true,
          },
          {
            title: "吊装费",
            key: "liftingFee",
            showTooltip: true,
          },
          {
            title: "过磅费",
            key: "weightFee",
            showTooltip: true,
          },
          {
            title: "卸车费",
            key: "unloadFee",
            showTooltip: true,
          },
          // {
          //   title: "应收金额",
          //   key: "orderCount",
          //   showTooltip: true,
          // },
        ],
        data: [],
      },
    };
  },
  methods: {
    handleSelectionChange(val){
      this.orderIdList = val.map(item=>{
        return item.orderId
      })
      this.$emit('getOrderIdList',this.orderIdList)
    }
  },
};
</script>

<style lang="css" scoped></style>
